import { Suspense, lazy } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'

import { store } from './redux/store'

import Layout from './components/Containers/Layout/Layout.jsx'
import ContentLayout from './components/Containers/ContentLayout/ContentLayout.jsx'
import PageLoader from './components/UI/PageLoader/PageLoader'

/* moment */
import moment from 'moment-timezone'
import 'moment/locale/it'
moment.tz.setDefault('Europe/Rome')

const HomeLazy = lazy(() => import('./components/Pages/Home/Home'))
const StructureLazy = lazy(() =>
  import('./components/Pages/Structure/Structure')
)
const GalleryLazy = lazy(() => import('./components/Pages/Gallery/Gallery'))
const VideosLazy = lazy(() => import('./components/Pages/Videos/Videos'))
const EventsLazy = lazy(() => import('./components/Pages/Events/Events'))
const CalendarLazy = lazy(() => import('./components/Pages/Calendar/Calendar'))
const PrivacyPolicyLazy = lazy(() =>
  import('./components/Pages/PrivacyPolicy/PrivacyPolicy')
)
const ActivitiesLazy = lazy(() =>
  import('./components/Pages/Activities/Activities')
)

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<PageLoader />}>
          <Routes>
            <Route element={<Layout />}>
              <Route index element={<HomeLazy />} />
              <Route element={<ContentLayout />}>
                <Route path='/struttura' element={<StructureLazy />} />
                <Route path='/eventi' element={<EventsLazy />} />
                <Route path='/gallery' element={<GalleryLazy />} />
                <Route path='/video' element={<VideosLazy />} />
                <Route path='/calendario' element={<CalendarLazy />} />
                <Route path='/privacy-policy' element={<PrivacyPolicyLazy />} />
                <Route path='/attivita' element={<ActivitiesLazy />} />
              </Route>
              <Route path='*' element={<HomeLazy />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </Provider>
  )
}

export default App
