import css from './WhatsApp.module.scss'

const WhatsApp = () => (
  <a
    href='https://wa.me/393337248456'
    target='_blank'
    rel='noreferrer'
    aria-label='Whatsapp'
    className={css.Whatsapp}
  >
    <i className='fa-brands fa-whatsapp'></i>
  </a>
)

export default WhatsApp
