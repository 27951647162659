export const uiConstants = {
  videos: [
    {
      title: 'Special Forces Training',
      url: 'IpRuLRDPqaE'
    },
    {
      title: 'Glock 17 Gen5 T4E',
      url: 'D18QUpaVh7o'
    },
    {
      title: 'HDP50',
      url: 'Vl43-nFlZog'
    },
    {
      title: 'HDS68',
      url: 'Nj8QJq9ts4U'
    },
    {
      title: 'Safety First - Facts',
      url: 'IrnB6uOF14M'
    },
    {
      title: 'Safety First - Safety and warning instructions',
      url: 'RCKTQJIYiws'
    }
  ],
  staff: [
    {
      name: 'Francesca',
      phone: '+393297634316'
    },
    {
      name: 'Mauro',
      phone: '+393337248456'
    },
    {
      name: 'Luca',
      phone: '+393494623924'
    },
    {
      name: 'Paolo',
      phone: '+393336461798'
    },
    {
      name: 'Simone',
      phone: '+393772114627'
    },
    {
      name: 'Andrea',
      phone: '+393475589539'
    },
    {
      name: 'Gian Pietro',
      phone: '+393401148117'
    }
  ],
  week: ['Lun', 'Mar', 'Mer', 'Gio', 'Ven', 'Sab', 'Dom'],
  book: [
    {
      label: 'Nome',
      name: 'name',
      type: 'text',
      required: true,
      placeholder: 'Nome'
    },
    {
      label: 'Cognome',
      name: 'surname',
      type: 'text',
      required: true,
      placeholder: 'Cognome'
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      required: false,
      placeholder: 'Email'
    },
    {
      label: 'Telefono',
      name: 'phone',
      type: 'tel',
      required: true,
      placeholder: 'Telefono'
    },
    {
      label: 'Ora inizio',
      name: 'startTime',
      type: 'select',
      options: new Array(16).fill(0).map((_, i) => {
        const t = i + 8
        const h = `0${t}`.slice(-2)
        return {
          label: `${h}:00`,
          value: h
        }
      }),
      required: true,
      placeholder: 'Ora inizio'
    },
    {
      label: 'Ora fine',
      name: 'endTime',
      type: 'select',
      options: new Array(15).fill(0).map((_, i) => {
        const t = i + 9
        const h = `0${t}`.slice(-2)
        return {
          label: `${h}:00`,
          value: h
        }
      }),
      required: true,
      placeholder: 'Ora fine'
    },
    {
      label: 'Numero persone',
      name: 'people',
      type: 'number',
      required: true,
      defaultValue: 1,
      min: 1,
      placeholder: 'Numero persone'
    },
    {
      label: 'Note',
      name: 'notes',
      type: 'textarea',
      required: false,
      placeholder:
        'Specifica se hai bisogno della nostra attrezzatura o se hai bisogno di informazioni aggiuntive.'
    }
  ],
  messages: {
    bookSuccess:
      'Prenotazione inviata con successo, ti contatteremo il prima possibile.',
    bookFailure: 'Errore durante la prenotazione, riprovare, grazie.'
  },
  notification: {
    info: 'Info',
    error: 'Error',
    warning: 'Warning',
    success: 'Success'
  },
  contacts: [
    {
      name: 'name',
      label: 'Nome Cognome',
      required: true,
      type: 'text',
      placeholder: 'Nome Cognome'
    },
    {
      name: 'email',
      label: 'Email',
      required: true,
      type: 'email',
      placeholder: 'Email'
    },
    {
      name: 'phone',
      label: 'Telefono',
      required: false,
      type: 'tel',
      placeholder: 'Telefono'
    },
    {
      name: 'message',
      label: 'Messaggio',
      required: true,
      type: 'textarea',
      placeholder: 'Messaggio'
    }
  ],
  emailjs: {
    serviceId: 'service_bgfivsn',
    templateId: 'template_lajzuc3',
    publicKey: 'xbvFbu_gf-skNaxtn'
  }
}
