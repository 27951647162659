import { Fragment } from 'react'
import { Outlet } from 'react-router-dom'

import T4E from './T4E/T4E'
import Contacts from './Contacts/Contacts'

import css from './ContentLayout.module.scss'

const ContentLayout = () => (
  <Fragment>
    <div className={css.Content}>
      <Outlet />
    </div>
    <T4E />
    <Contacts />
  </Fragment>
)

export default ContentLayout
