import css from './T4E.module.scss'

const T4E = () => (
  <div className={css.Container}>
    <ul className={css.Ult4e}>
      <li>
        Perché <span>T4E Umarex?</span>
      </li>
      <li>
        T4E è l'acronimo di "<strong>Training for Engagement</strong>", una
        nuova generazione di armi Umarex da allenamento di alta gamma che
        utilizzano palline di gesso, palline di gomma o paintball.
        <br />
        <br />
        Sono alimentate da cartucce di CO₂ e, a seconda del modello,
        producono&nbsp;
        <strong>un'energia che va da 4 a 16 Joule</strong>, generando un effetto
        blowback realistico.
        <br />
        <br />
        Potrai quindi finalmente testare le tue abilità contro aggressori
        "reali", e non contro le classiche statiche sagome di cartone, potrai
        migliorare la tua tecnica con una formazione realistica e sicura.
        <br />
        <br />
        Per usare le T4E <strong>NON è necessario avere il porto d'armi</strong>
        , in caso di minori è necessaria la presenza di un adulto.
      </li>
    </ul>
  </div>
)

export default T4E
